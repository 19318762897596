@import 'styles/system/variables';

.container {
  width: 100%;
}

.input {
  appearance: none;
  color: $color--gray--darkest;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  max-width: 50%;
}

.field {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  background-color: $color--primary--lightest;
  border: 1px solid $color--gray--light;
  border-radius: $border-radius--medium;
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  width: 100%;
  :global {
    svg {
      fill: $color--gray--dark;
    }
  }
  &:focus-visible {
    outline: 1px solid $color--primary--light;
    box-shadow: $shadow--small;
  }
  &[disabled] {
    color: $color--gray;
    background-color: $color--background--dark;
    &:hover {
      cursor: not-allowed;
    }
  }
  &__value {
    font-size: 0.7rem;
    &__container {
      display: flex;
      align-items: center;
      color: $color--gray--darkest;
      background-color: $color--primary--lightest;
      border: thin solid $color--primary--light;
      border-radius: $border-radius--medium;
      box-shadow: $shadow--small;
      min-height: 1.5rem;
      padding: 0 0.5rem;
    }
    &__clear {
      display: flex;
      margin-left: 0.25rem;
      :global svg {
        fill: $color--gray--dark;
      }
    }
  }
  &--error {
    border: 1px solid $color--negative--light;
    &:focus-visible {
      outline: 1px solid $color--negative;
    }
  }
  &--with-icon {
    padding-left: 2.25rem;
  }
  &__caret {
    position: absolute;
    right: 0.25rem;
    top: 0.5rem;
    color: $color--gray--dark;
  }
  &__clearable {
    position: absolute;
    cursor: pointer;
    color: $color--gray--dark;
    background: $color--primary--lightest;
    display: flex;
    height: 16px;
    top: 8px;
    right: 1.5rem;
  }
  &__icon {
    position: absolute;
    z-index: $z-index--control-mark;
    left: 0.75rem;
    top: 0.5rem;
    margin: auto;
    color: $color--gray--dark;
  }
  &__menu {
    position: absolute;
    width: 250px;
    list-style: none;
    padding: 0.5rem 0;
    background-color: $color--white;
    border: $border--small;
    border-radius: $border-radius--large;
    box-shadow: $shadow--medium;
    margin-top: 0.5rem;
    &__anchor {
      position: relative;
    }
    &__item {
      padding: 0.5rem 1rem;
      color: $color--gray--darkest;
      &--disabled {
        padding: 0.5rem 1rem;
        color: $color--gray;
        &:hover {
          cursor: not-allowed;
        }
      }
      &--active {
        padding: 0.5rem 1rem;
        color: $color--primary;
        background-color: $color--primary--lightest;
      }
      &:hover {
        color: $color--primary;
        background-color: $color--primary--lightest;
      }
    }
  }
}

.label {
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
  color: $color--gray--dark;
  &--disabled {
    color: $color--gray;
  }
  &--error {
    color: $color--negative;
  }
}

.hint {
  padding: 0 0.25rem;
  &--error {
    color: $color--negative;
  }
}
