@import 'styles/system/variables';

.container {
  width: 100%;
}

.field {
  position: relative;
  appearance: none;
  color: $color--gray--darkest;
  background-color: $color--primary--lightest;
  border: 1px solid $color--gray--light;
  border-radius: $border-radius--medium;
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  width: 100%;
  :global {
    svg {
      fill: $color--gray--dark;
    }
  }
  &:focus-visible {
    outline: 1px solid $color--primary--light;
    box-shadow: $shadow--small;
  }
  &[disabled] {
    color: $color--gray;
    background-color: $color--background--dark;
    &:hover {
      cursor: not-allowed;
    }
  }
  &--error {
    border: 1px solid $color--negative--light;
    &:focus-visible {
      outline: 1px solid $color--negative;
    }
  }
  &--with-icon {
    padding-left: 2.25rem;
  }
  &__wrapper {
    position: relative;
    display: flex;
    min-height: 30px;
  }
  &__caret {
    position: absolute;
    right: 0.25rem;
    top: 0.5rem;
    color: $color--gray--dark;
  }
  &__clearable {
    position: absolute;
    cursor: pointer;
    color: $color--gray--dark;
    background: $color--primary--lightest;
    display: flex;
    height: 16px;
    top: 8px;
    right: 1.5rem;
  }
  &__icon {
    position: absolute;
    z-index: $z-index--control-mark;
    left: 0.75rem;
    top: 0.5rem;
    margin: auto;
    color: $color--gray--dark;
  }
  &__menu {
    position: absolute;
    width: 250px;
    list-style: none;
    padding: 0.5rem 0;
    background-color: $color--white;
    border: $border--small;
    border-radius: $border-radius--large;
    box-shadow: $shadow--medium;
    margin-top: 0.5rem;
    &__anchor {
      position: relative;
    }
    &__item {
      padding: 0.5rem 1rem;
      color: $color--gray--darkest;
      &--disabled {
        padding: 0.5rem 1rem;
        color: $color--gray;
        &:hover {
          cursor: not-allowed;
        }
      }
      &--active {
        padding: 0.5rem 1rem;
        color: $color--primary;
        background-color: $color--primary--lightest;
      }
      &:hover {
        color: $color--primary;
        background-color: $color--primary--lightest;
      }
    }
  }
}

.label {
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
  color: $color--gray--dark;
  &--disabled {
    color: $color--gray;
  }
  &--error {
    color: $color--negative;
  }
}

.hint {
  padding: 0 0.25rem;
  &--error {
    color: $color--negative;
  }
}
